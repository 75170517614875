@tailwind components;

@layer components {
  .nandoya-login {
    @apply flex items-center justify-center [&_.amplify-button--primary]:bg-main [&_.amplify-tabs-item]:text-main [&_.amplify-button--link]:text-main whitespace-pre-wrap;
  }

  .nandoya-login .amplify-tabs-item[data-state="active"] {
    @apply !border-main !text-main;
  }

  button[class="amplify-tabs-item"]:nth-child(2)::after {
    content: "\A (初めての方はこちら)";
  }

  [data-amplify-authenticator] {
    --amplify-components-tabs-item-padding-vertical: 8px;
    --amplify-components-tabs-item-padding-horizontal: 0;
  }
}
