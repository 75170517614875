@tailwind components;

@layer components {
  .nandoya-table {
    @apply -mx-8 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:max-w-3xl md:rounded-lg sm:mx-auto;
  }

  .nandoya-table table {
    @apply w-full divide-y divide-gray-300;
  }

  .nandoya-table thead {
    @apply bg-gray-50;
  }

  .nandoya-table th {
    @apply py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6;
  }

  .nandoya-table tbody {
    @apply divide-y divide-gray-200 bg-white;
  }

  .nandoya-table td {
    @apply py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6;
  }
}
