@tailwind components;

@layer components {
  .nandoya-carousel {
    @apply mt-8 mx-auto w-4/5 sm:w-2/5;
  }

  .nandoya-carousel .slide {
    @apply my-auto;
  }

  .nandoya-carousel .carousel-img {
    @apply max-h-56 object-contain block sm:max-h-96;
  }

  .nandoya-carousel .slider {
    @apply flex items-center;
  }

  .nandoya-carousel .thumbs {
    @apply flex items-center;
  }
}
