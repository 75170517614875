@tailwind components;

@layer components {
  .nandoya-btn {
    @apply block w-56 h-14 items-center rounded-md border border-transparent bg-sub px-4 py-2 text-base font-medium text-black shadow-md hover:bg-subHover focus:outline-none focus:ring-2 focus:ring-sub focus:ring-offset-2 mx-auto cursor-pointer disabled:cursor-not-allowed;
  }

  .nandoya-btn img {
    @apply w-10 m-auto;
  }

  .nandoya-btn:disabled {
    @apply bg-gray-300 text-white;
  }

  .sub-mode {
    @apply bg-white !border-sub;
  }
}
