@tailwind components;

@layer components {
  .nandoya-carousel-modal {
    @apply mt-8 mx-auto;
  }

  .nandoya-carousel-modal .slide {
    @apply my-auto;
  }

  .nandoya-carousel-modal .carousel-img {
    @apply max-h-56 object-contain block sm:max-h-96;
  }

  .nandoya-carousel-modal .slider {
    @apply flex items-center;
  }

  .nandoya-carousel-modal .control-arrow {
    @apply !bg-gray-500/80;
  }

  .nandoya-carousel-modal .thumbs {
    @apply flex items-center;
  }
}
