@tailwind components;

@layer components {
  .nandoya-input label {
    @apply block text-sm font-medium text-black sm:text-base;
  }

  .nandoya-input .input-div {
    @apply relative mt-1 rounded-md shadow-sm;
  }

  .nandoya-input input {
    @apply block w-full rounded-md sm:text-sm disabled:cursor-not-allowed disabled:border-gray-400 disabled:bg-gray-200;
  }

  .nandoya-input .err-input {
    @apply border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500;
  }

  .nandoya-input .err-ex {
    @apply pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3;
  }

  .nandoya-input .err-ex-icon {
    @apply h-5 w-5 text-red-500;
  }

  .nandoya-input .err-msg {
    @apply mt-2 text-sm text-red-600;
  }

  .nandoya-input small {
    @apply block mt-[-4px] text-red-400;
  }

  .nandoya-input input[type="date"] {
    @apply relative;
  }

  .nandoya-input input[type="date"]::-webkit-calendar-picker-indicator {
    @apply absolute w-full h-full cursor-pointer;
  }

  .nandoya-input input[type="date"]::-webkit-calendar-picker-indicator {
    @apply opacity-0;
  }
}
